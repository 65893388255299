import { useCombobox } from "downshift";

import "./AutoComplete.css";

export default function AutoComplete({
	Item,
	items,
	label,
	placeholder,
	className,
	onChange,
	onInputValueChange,
}) {
	const {
		isOpen,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		highlightedIndex,
		getItemProps,
	} = useCombobox({
		items,
		itemToString: (item) => (item ? item.name : ""),
		onInputValueChange: onInputValueChange,
		onSelectedItemChange: onChange,
	});
	return (
		<div className={className}>
			<label className="row" {...getLabelProps()}>
				{label}
				&nbsp;
				<div {...getComboboxProps()}>
					<input
						{...getInputProps()}
						type="search"
						placeholder={placeholder}
					/>
				</div>
			</label>
			<ul {...getMenuProps()} className="PopOver">
				{isOpen &&
					items.map((item, index) => (
						<Item
							key={`${item.id}`}
							data={item}
							highlight={highlightedIndex === index}
							extendedProps={getItemProps({ item, index })}
						/>
					))}
			</ul>
		</div>
	);
}
