import { Fragment, useState, useEffect, useRef } from "react";
import Blank from "./Blank";
import Certificate from "./Certificate";
import SoundManager from "../components/SoundManager";
import { getTeam, getStorageFileUrl } from "../utils/store-functions";

import backendUrl from "../utils/backend-url";

import "./Frontpage.css";

const soundManager = new SoundManager();
soundManager.addTrack("ambientLoop");
soundManager.addTrack("energyLoop");
soundManager.addTrack("applause");

// @todo refactor into config
const imageMinSize = 400;
const imageMaxSize = 1500;

export default function Frontpage({ user, participant, signOut }) {
	const batches = participant.batches
		? Object.values(participant.batches)
		: [];

	const lastBatchIndex = batches.length > 0 ? batches.length - 1 : null;

	const fileInput = useRef(null);
	const [selectedBatch, setSelectedBatch] = useState(lastBatchIndex);
	const [team, setTeam] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [certificateUrl, setCertificateUrl] = useState(null);
	const [socialPreviewUrl, setSocialPreviewUrl] = useState(null);

	const [enabling, setEnabling] = useState(false);
	const [disabling, setDisabling] = useState(false);
	const [retrieving, setRetrieving] = useState(true);
	const [imageIsProcessing, setImageIsProcessing] = useState(false);
	const [soundOn, setSoundOn] = useState(true);
	const [copiedToClipboard, setCopiedToClipboard] = useState(false);
	const [batch, setBatch] = useState(
		selectedBatch != null ? batches[selectedBatch] : null,
	);
	const [showSocialPreview, setShowSocialPreview] = useState(false);

	const batchList = batches.map((b, i) => (
		<option key={i} value={i}>
			Batch {b.batch}
		</option>
	));

	const onClickEnableSharing = () => {
		setEnabling(true);
		user.getIdToken().then((token) => {
			fetch(`${backendUrl}enableSharing`, {
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {
					Authorization: "Bearer " + token,
					"Participant-Batch": batch.batch,
				},
			})
				.then((response) => response.json())
				.then((result) => {
					setEnabling(false);
					setBatch({
						...batch,
						shareId: result.shareId,
						shared: true,
					});
				})
				.catch((error) => {
					setEnabling(false);
					console.error("Failed to fetch sharing link");
				});
		});
	};

	const onClickDisableSharing = () => {
		setDisabling(true);
		user.getIdToken().then((token) => {
			fetch(`${backendUrl}disableSharing`, {
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {
					Authorization: "Bearer " + token,
					"Participant-Batch": batch.batch,
				},
			})
				.then((response) => {
					if (response.ok) {
						setDisabling(false);
						setBatch({ ...batch, shared: false });
					}
				})
				.catch((error) => {
					setDisabling(false);
					console.error("Failed to fetch sharing link");
				});
		});
	};

	const onClickUpload = () => {
		fileInput.current.click();
	};

	// @todo refactor into helper function (look up image resize code in dps-upload project)
	const onImageSelected = (event) => {
		const selectedFile = event.target.files[0];
		const readerDataUrl = new FileReader();

		// Read image into memory as base64 encoded data url
		readerDataUrl.addEventListener("load", () => {
			// Sanity checks on image dimensions
			const img = new Image();
			img.onload = function () {
				let valid = true;
				if (this.width < imageMinSize || this.height < imageMinSize) {
					valid = false;
					window.alert(
						`Please use an image larger than ${imageMinSize} pixels`,
					);
				} else if (
					this.width > imageMaxSize ||
					this.height > imageMaxSize
				) {
					valid = false;
					window.alert(
						`Please use an image smaller than ${imageMaxSize} pixels`,
					);
				} else {
					setImageIsProcessing(true);
					// setWarning(null);
				}

				if (valid) {
					user.getIdToken().then((token) => {
						return fetch(`${backendUrl}addPicture`, {
							method: "POST",
							cache: "no-cache",
							headers: {
								Authorization: "Bearer " + token,
								"Content-Type": selectedFile.type,
								"X-User": participant.email,
							},
							body: selectedFile, // file or blob
						})
							.then((response) => {
								if (response.ok) return response;
								throw response;
							})
							.then(() => {
								setImageIsProcessing(false);

								getStorageFileUrl(
									`participants/${participant.email}/texture.png`,
								).then(setImageUrl);

								window.setTimeout(() => {
									getStorageFileUrl(
										`participants/${participant.email}/${batch.batch}/social-preview.png`,
									).then((url) => {
										fetch(url, {
											cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
										})
											.then((response) => {
												if (response.ok) {
													return response.blob();
												} else {
													throw new Error(
														`Could not download picture: ${response.statusText}`,
													);
												}
											})
											.then((blob) => {
												const dataUrl =
													URL.createObjectURL(blob);
												setSocialPreviewUrl(dataUrl);
											});
									});
								}, 3000);
							})
							.catch((error) => {
								console.error(error);
							});
					});
				}
			};
			img.src = readerDataUrl.result;
		});
		readerDataUrl.readAsDataURL(selectedFile);

		// Reset input to allow selecting the same image again
		event.target.value = "";
	};

	useEffect(() => {
		if (participant.email) {
			getStorageFileUrl(
				`participants/${participant.email}/texture.png`,
			).then(setImageUrl);
		}
	}, [participant]);

	useEffect(() => {
		if (batch && batch.team) {
			getTeam(batch.team).then(setTeam);
		}
	}, [batch]);

	useEffect(() => {
		if (participant && batch.batch && user) {
			user.getIdToken().then((token) => {
				setRetrieving(true);
				getStorageFileUrl(
					`participants/${participant.email}/${batch.batch}/certificate.pdf`,
				).then((url) => {
					setCertificateUrl(url);
					setRetrieving(false);
				});

				getStorageFileUrl(
					`participants/${participant.email}/${batch.batch}/social-preview.png`,
				).then(setSocialPreviewUrl);

				/*getPrivateUrls(batch.batch, participant.email, token).then(
					(urls) => {
						setPrivateCertificate(urls);
						setRetrieving(false);
					},
				);*/
			});
		}
	}, [participant, batch, user]);

	useEffect(() => {
		soundManager.setCallback(setSoundOn);
		soundManager.play("ambientLoop");
		if (soundOn) {
			soundManager.resumePlaying();
		} else {
			soundManager.pauseAll();
		}
	}, [soundOn]);

	return (
		<div className="Frontpage col center justify-center">
			{batch && batch.released ? (
				<Certificate
					user={user}
					participant={participant}
					batch={batch}
					imageUrl={imageUrl}
					soundManager={soundManager}
				/>
			) : (
				<Blank
					user={user}
					signOut={signOut}
					error={{
						message: (
							<span>
								Your certificate has not been released yet.
								<br />
								Please talk to your track head.
							</span>
						),
					}}
				/>
			)}

			{lastBatchIndex > 0 && (
				<div className="Overlay top left BatchSelect">
					<select
						size="1"
						value={selectedBatch}
						onChange={(event) => {
							const number = parseInt(event.target.value);
							setSelectedBatch(number);
							setBatch(batches[number]);
						}}
					>
						{batchList}
					</select>
				</div>
			)}

			{team && (
				<div className="Overlay bottom left hide-on-mobile TeamInfo">
					<label>Track</label>
					{batch.track}
					<label>
						<br />
						Team
					</label>
					{team.name}
					<label>
						<br />
						Name
					</label>
					{participant.name}
				</div>
			)}

			<div className="Overlay top left">
				<button
					className="SoundSetting"
					onClick={() =>
						soundOn
							? soundManager.pauseAll()
							: soundManager.resumePlaying()
					}
				>
					<img
						src={
							soundOn
								? "/assets/icon-sound-on.svg"
								: "/assets/icon-sound-off.svg"
						}
						alt="Turn sound on and off"
					/>
				</button>
			</div>
			<div className="Overlay top right">
				<button
					className="Logout"
					onClick={() => {
						soundManager.pauseAll();
						signOut();
					}}
				>
					<img src="/assets/icon-logout.svg" alt="Sign out" />
				</button>
			</div>

			{batch && batch.released ? (
				<div className="Overlay bottom right DownloadArea">
					<label>Status</label>
					Approved
					<label>
						<br />
						Download
					</label>
					<div className="row center justify-end">
						{certificateUrl && (
							<a
								href={certificateUrl}
								onClick={() => soundManager.play("applause")}
								className={`link`}
								target="_blank"
								rel="noopener noreferrer"
							>
								Certificate
							</a>
						)}
						{retrieving && (
							<Fragment>
								&nbsp;
								<div className="loader">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</Fragment>
						)}
					</div>
					<label>
						<br />
						Social Media Link
					</label>
					{socialPreviewUrl && (
						<div
							className={`social-preview ${
								showSocialPreview ? "show" : "hide"
							}`}
						>
							<img
								src={socialPreviewUrl}
								alt="Social Media Preview"
							/>
						</div>
					)}
					{/* 
					TODO
						– check if sharedId already exists – offer to copy URL
						– create disableSharing action
					*/}
					<div className="row">
						<button
							onClick={onClickUpload}
							className="row center"
							disabled={imageIsProcessing}
						>
							Change Photo
							{imageIsProcessing && (
								<>
									&nbsp;
									<div className="loader">
										<div></div>
										<div></div>
										<div></div>
										<div></div>
									</div>
								</>
							)}
						</button>
						<input
							ref={fileInput}
							onChange={onImageSelected}
							type="file"
							accept="image/jpeg,image/png"
							className="hidden"
						/>
						&nbsp;
						{batch.shared ? (
							<>
								<button
									className=""
									onMouseEnter={() =>
										setShowSocialPreview(true)
									}
									onMouseLeave={() =>
										setShowSocialPreview(false)
									}
									onClick={() => {
										navigator.clipboard
											.writeText(
												`${window.location.origin}/shared/${batch.shareId}`,
											)
											.then(
												() => {
													soundManager.play(
														"applause",
													);
													setCopiedToClipboard(true);
													setTimeout(
														() =>
															setCopiedToClipboard(
																false,
															),
														2000,
													);
												},
												(err) =>
													window.alert(
														`Could not copy link. ${err}`,
													),
											);
									}}
								>
									{copiedToClipboard ? "✔︎ Done" : "Copy Url"}
								</button>
								&nbsp;
								<button
									onClick={onClickDisableSharing}
									disabled={disabling}
									className="row center"
								>
									Disable
									{disabling && (
										<Fragment>
											&nbsp;
											<div className="loader">
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										</Fragment>
									)}
								</button>
							</>
						) : (
							<button
								onMouseEnter={() => setShowSocialPreview(true)}
								onMouseLeave={() => setShowSocialPreview(false)}
								onClick={onClickEnableSharing}
								disabled={enabling}
								className="row center"
							>
								Enable public link
								{enabling && (
									<Fragment>
										&nbsp;
										<div className="loader">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</Fragment>
								)}
							</button>
						)}
					</div>
				</div>
			) : (
				<div className="Overlay bottom right DownloadArea">
					<label>Status</label>
					{batch.approved ? "Not released" : "Not approved"}
				</div>
			)}
		</div>
	);
}
