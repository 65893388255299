import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CSVDropZone from "../../components/CSVDropZone";
import TaskRunner from "../../components/TaskRunner";
import Tooltip from "../../components/Tooltip";
import {
	getTeams,
	addTeam,
	importParticipant,
} from "../../utils/store-functions";
import testEmail from "../../utils/test-email";

import { tracks, templates } from "../../config/dps";

export default function BatchImport(props) {
	let { batchId } = useParams();
	const [teams, setTeams] = useState([]);
	const [data, setData] = useState([]);

	const rows = data.map((row, i) => {
		return (
			<tr key={i}>
				<td>{row.batch}</td>
				<td>{row.name}</td>
				<td>
					{row.email}{" "}
					{!testEmail(row.email, "dpschool.io") && (
						<Tooltip
							label="⚠️"
							info="The email should end in @dpschool.io"
						/>
					)}
				</td>
				<td>
					{row.program}{" "}
					{!templates[row.program] && (
						<Tooltip
							label="⚠️"
							info="This program is not supported."
						/>
					)}
				</td>
				<td>
					{row.track}{" "}
					{tracks.indexOf(row.track) < 0 && (
						<Tooltip
							label="⚠️"
							info="This track is not supported."
						/>
					)}
				</td>
				<td>
					{row.team}{" "}
					{!row.team && (
						<Tooltip label="⚠️" info="Please add a team name." />
					)}
				</td>
			</tr>
		);
	});

	const findOrCreateTeam = (teamName, batchNr) => {
		const team = teams.find(
			(t) => t.name === teamName && t.batch === batchNr
		);
		if (team) {
			return new Promise((resolve) => resolve(team));
		} else {
			return addTeam({ name: teamName, batch: batchNr }).then((team) => {
				const teamsUpdate = [...teams, team];
				setTeams(teamsUpdate);
				return team;
			});
		}
	};

	const onProcessRow = (row, i) => {
		return findOrCreateTeam(row.team, row.batch).then((team) => {
			const participant = {
				email: row.email,
				name: row.name,
				nameLower: row.name.toLowerCase(),
			};

			const batchNr = row.batch;

			const details = {
				batch: batchNr, // necessary for Firebase queries
				program: row.program,
				track: row.track,
				team: team.id,
				template: row.template,
			};

			if (templates[row.program] && templates[row.program][row.track]) {
				details.template = templates[row.program][row.track][0];
			} else {
				console.warn("No template found for ", participant);
			}

			console.log("Importing", i, participant, batchNr, details);
			return importParticipant(participant, batchNr, details);
		});
	};

	useEffect(() => {
		getTeams(batchId).then(setTeams);
	}, [batchId]);

	return (
		<div className="Import col list grow">
			<b>Step 1</b>
			<p>
				Prepare the list of participants for this batch as a CSV file in
				the tool of your choice (Google Spreadsheets, Excel, Numbers or
				similar). Please make sure that you have all the required
				columns and use only accepted values for the track and program
				columns.{" "}
				<a href="/assets/import-example.csv">
					Download an example CSV ⬇︎
				</a>
			</p>
			<a href="/assets/import-example.csv">
				<img
					src="/assets/import-example.png"
					alt="Example of the supported CSV columns"
				/>
			</a>
			<br />
			<div>
				Accepted values for <b>programs</b> are:{" "}
				{Object.keys(templates).join(", ")}
			</div>
			<div>
				Accepted values for <b>tracks</b> are: {tracks.join(", ")}
			</div>
			<br />
			<br />
			<b>Step 2</b>
			<p>
				Upload the prepared CSV here to see a preview of the
				participants that will be imported. When the preview looks good,
				you can click &lt;Start&gt; to begin. When all rows have been
				imported you're done and can navigate to the list of
				participants.
			</p>
			<div>
				<CSVDropZone onData={setData} />
				<div>
					<TaskRunner
						items={data}
						itemLabel="rows to import"
						task={onProcessRow}
						onFinish={() => console.log("All Done")}
						startLabel="Start import"
						tooltip={
							<Tooltip
								info={
									"Clicking Start will import the rows from the CSV into the database one by one. A row with an email that already exists in the database will not create a new participant, but will try to update the existing one."
								}
							/>
						}
					/>
				</div>
			</div>
			<br />
			<br />
			<br />
			<div className="row">Preview of participants to import</div>

			<table>
				<thead>
					<tr>
						<th>Batch</th>
						<th>Name</th>
						<th>Email</th>
						<th>Program</th>
						<th>Track</th>
						<th>Team</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</div>
	);
}
