import "./Blank.css";

export default function Blank({ user, signOut, error }) {
	return (
		<div className="Blank col center justify-center">
			<img src="/assets/logos/dps-gold.svg" alt="DPS Logo" />
			{error && <p>{error.message}</p>}
			{user && (
				<div className="Overlay top right">
					<button className="Logout" onClick={signOut}>
						<img
							src="/assets/icon-logout.svg"
							alt="Sign out"
						/>
					</button>
				</div>
			)}
		</div>
	);
}
