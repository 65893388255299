import { Fragment, useState, useEffect } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom";
import { subscribeBatchParticipants } from "../../utils/store-functions";

import { tracks } from "../../config/dps";

export default function BatchParticipants() {
	let { batchId } = useParams();
	const [participants, setParticipants] = useState([]);
	const [filter, setFilter] = useState(null);

	useEffect(() => {
		const unsubscribe = subscribeBatchParticipants(
			batchId,
			setParticipants
		);
		return function cleanup() {
			unsubscribe();
		};
	}, [batchId]);

	const filterOptions = tracks.map((track, i) => {
		return (
			<button
				key={`f${i}`}
				onClick={() => setFilter(track)}
				className={filter === track ? "active" : ""}
			>
				{track}
			</button>
		);
	});

	let filteredParticipants = participants;
	if (filter) {
		filteredParticipants = participants.filter(
			(p) => p.batches[batchId] && p.batches[batchId].track === filter
		);
	}
	const participantList = filteredParticipants.map((p, i) => {
		let icon = <img src="/assets/icon-declined.svg" width="20" alt="" />;
		if (batchId && p.batches && p.batches[batchId]) {
			const details = p.batches[batchId];
			if (details.approved && details.released) {
				icon = (
					<img
						src="/assets/icon-check-double.svg"
						width="20"
						alt=""
					/>
				);
			} else if (details.approved) {
				icon = (
					<img
						src="/assets/icon-check-single.svg"
						width="20"
						alt=""
					/>
				);
			}
		}
		return (
			<NavLink to={`${p.id}`} key={i} className="row center">
				{icon}&ensp;{p.name}
				{p.picture && " 📷"}
				{p.batches[batchId] && p.batches[batchId].shared && " 🔖"}
			</NavLink>
		);
	});

	return (
		<Fragment>
			<div className="col list">
				<div className="Filter">
					<button
						onClick={() => setFilter(null)}
						className={filter === null ? "active" : ""}
					>
						All
					</button>
					{filterOptions}
				</div>
				{participantList}
			</div>
			<Outlet />
		</Fragment>
	);
}
