const configDevelopment = {
  apiKey: "AIzaSyDS8hQsIGV0CRT4lC66TjNKmY6mEAhlzXg",
  authDomain: "dps-certificates-dev.firebaseapp.com",
  databaseURL: "https://dps-certificates-dev.firebaseio.com",
  projectId: "dps-certificates-dev",
  storageBucket: "dps-certificates-dev.appspot.com",
  messagingSenderId: "854826447657",
  appId: "1:854826447657:web:65382493973ce749eb3d38",
};

const configProduction = {
  apiKey: "AIzaSyBlyS_SX0TXsUm7RbGiJM1U19xayc1vGrM",
  authDomain: "certificates.dpschool.io",
  databaseURL: "https://dps-certificates.firebaseio.com",
  projectId: "dps-certificates",
  storageBucket: "dps-certificates.appspot.com",
  messagingSenderId: "1024338005417",
  appId: "1:1024338005417:web:e9d306cdd6b5a884f688b8",
};

// Which configuration shall we use?
const currentConfig =
  process && process.env && process.env.REACT_APP_ENV === "dev"
    ? configDevelopment
    : configProduction;

export const config = currentConfig;
export default currentConfig;
