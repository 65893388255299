import { Fragment } from "react";
import { Outlet, NavLink } from "react-router-dom";

export default function Settings({ batches }) {
	return (
		<Fragment>
			<div className="col list">
				<h3>Settings</h3>
				<NavLink to="batches">Batches</NavLink>
				<NavLink to="user">User</NavLink>
			</div>

			<Outlet />
		</Fragment>
	);
}
