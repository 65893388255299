import { Fragment, useRef, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { getRoles, saveRoles } from "../../utils/store-functions";
import backendUrl from "../../utils/backend-url";
import testEmail from "../../utils/test-email";

function sortEmails(a, b) {
	return a.localeCompare(b, "en", {
		numeric: true,
		sensitivity: "base",
	});
}

export default function UserList({ user }) {
	const [roles, setRoles] = useState(null);
	const [isAdding, setIsAdding] = useState(false);
	const inputRef = useRef();
	let admins = [];

	const addAdmin = () => {
		if (
			inputRef.current.value &&
			inputRef.current.value.length > 0 &&
			testEmail(inputRef.current.value, [
				"unternehmertum.de",
				"dpschool.io",
			])
		) {
			admins.push(inputRef.current.value);
			admins.sort(sortEmails);
			const updatedRoles = { ...roles, admins };
			saveRoles(updatedRoles)
				.then(() => {
					setRoles(updatedRoles);
					setIsAdding(false);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			console.log(
				"Not an allowed email, please use an @unternehmertum.de or @dpschool.io email address."
			);
			window.alert(
				"Not an allowed email, please use an @unternehmertum.de or @dpschool.io email address."
			);
		}
	};

	const removeAdmin = (index) => {
		admins.splice(index, 1);
		const updatedRoles = { ...roles, admins };
		saveRoles(updatedRoles)
			.then(() => {
				setRoles(updatedRoles);
				setIsAdding(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const setClaims = (adminEmail) => {
		console.log(user);
		user.getIdToken().then((token) => {
			fetch(`${backendUrl}setClaims`, {
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {
					Authorization: "Bearer " + token,
					"X-User": adminEmail,
				},
			}).then((response) => {
				if (response.ok) {
					user.getIdToken(true);
				}
			});
		});
	};

	const removeClaims = (adminEmail) => {
		console.log(user);
		user.getIdToken().then((token) => {
			fetch(`${backendUrl}removeClaims`, {
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {
					Authorization: "Bearer " + token,
					"X-User": adminEmail,
				},
			}).then((response) => {
				if (response.ok) {
					user.getIdToken(true);
				}
			});
		});
	};

	useEffect(() => {
		getRoles().then(setRoles);
	}, [setRoles]);

	useEffect(() => {
		isAdding && inputRef.current.focus();
	}, [isAdding]);

	if (roles && roles.admins) {
		admins = Array.from(roles.admins);
		admins.sort(sortEmails);
	}

	const userList = admins.map((u, i) => (
		<tr key={`adm${i}`}>
			<td>{u}&nbsp;</td>
			<td>
				<button onClick={() => removeAdmin(i)}>Remove</button>
				<button onClick={() => setClaims(u)}>Set Claims</button>
				<button onClick={() => removeClaims(u)}>Remove Claims</button>
			</td>
		</tr>
	));

	return (
		<Fragment>
			<div className="col list">
				<h3>Admins</h3>
				<button onClick={() => setIsAdding(true)}>Add admin</button>
				{isAdding && (
					<div className="row">
						<button
							onClick={() => {
								setIsAdding(false);
							}}
						>
							Cancel
						</button>
						<input ref={inputRef} type="text" className="grow" />
						<button onClick={addAdmin}>Save</button>
					</div>
				)}
				&nbsp;
				<table>
					<tbody>{userList}</tbody>
				</table>
			</div>
			<Outlet />
		</Fragment>
	);
}
