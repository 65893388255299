import { useState } from "react";
import { useCSVReader } from "react-papaparse";

import "./CSVDropZone.css";

export default function CSVDropZone({ onData }) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);

  return (
    <CSVReader
      config={{
        header: true,
      }}
      onUploadAccepted={(results) => {
        // console.log("CSV parsed:", results);
        onData(results.data);
        setZoneHover(false);
      }}
      onDragOver={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({ getRootProps, acceptedFile, getRemoveFileProps }: any) => (
        <div
          {...getRootProps()}
          className={`CSVDropZone${zoneHover ? " hover" : ""} col justify-center`}
        >
          {acceptedFile
            ? acceptedFile.name
            : "⬇︎ Drag and drop CSV file here or click to upload"}
        </div>
      )}
    </CSVReader>
  );
}
