import { Fragment, useState, useEffect } from "react";

export default function TaskRunner({
	items,
	itemLabel,
	startLabel,
	task,
	onFinish,
	tooltip,
}) {
	const [current, setCurrent] = useState(0);
	const [isRunning, setIsRunning] = useState(false);

	const onPlayPause = () => {
		setIsRunning(!isRunning);
	};

	const onReset = () => {
		setIsRunning(false);
		setCurrent(0);
	};

	useEffect(() => {
		if (isRunning && current < items.length) {
			if (task) {
				task(items[current], current)
					.then(() => setCurrent(current + 1))
					.catch((error) => {
						setIsRunning(false);
						console.error(error);
					});
			}
		} else if (current === items.length) {
			setIsRunning(false);
			if (onFinish) onFinish();
		}
	}, [isRunning, current, items, task, onFinish]);

	return (
		<div className="TaskRunner row center">
			<div>{items && `${current}/${items.length} ${itemLabel}`}</div>
			&emsp;
			<div className="row center">
				<button onClick={onPlayPause}>
					{isRunning ? "Pause" : startLabel ? startLabel : "Start"}
				</button>
				&ensp;
				<button onClick={onReset}>Reset</button>
				{tooltip && <Fragment>&ensp;{tooltip}</Fragment>}
			</div>
		</div>
	);
}
