// After an example from here: https://codesandbox.io/s/wobbling-sphere-5oufp
// and here: https://onion2k.hashnode.dev/texturing-a-sphere-with-react-three-fiber

import { MathUtils, TextureLoader, RepeatWrapping } from "three";
import { useEffect, useState, useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import {
  PerspectiveCamera,
  MeshDistortMaterial,
  ContactShadows,
} from "@react-three/drei";
import { useSpring } from "@react-spring/core";
import { a } from "@react-spring/three";

// React-spring animates native elements, in this case <mesh/> etc,
// but it can also handle 3rd–party objs, just wrap them in "a".
const AnimatedMaterial = a(MeshDistortMaterial);

export default function WobbleSphereImage({
  imageUrl,
  highlightColor,
  backgroundColor,
  hover,
  onHover,
  onClick,
}) {
  const sphere = useRef();
  const light = useRef();
  const [down, setDown] = useState(false);

  const repeatX = 2;
  const repeatY = 1;

  // Change cursor on hover state
  useEffect(() => {
    document.body.style.cursor = hover
      ? "none"
      : `url('data:image/svg+xml;base64,${btoa(
          '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="10" fill="#E8B059"/></svg>'
        )}'), auto`;
  }, [hover]);

  // Make the bubble float and follow the mouse
  // This is frame-based animation, useFrame subscribes the component to the render-loop
  useFrame((state) => {
    if (light.current) {
      light.current.position.x = state.mouse.x * 20;
      light.current.position.y = state.mouse.y * 20;
    }
    if (sphere.current) {
      sphere.current.position.x = MathUtils.lerp(
        sphere.current.position.x,
        hover ? state.mouse.x / 2 : 0,
        0.2
      );
      sphere.current.position.y = MathUtils.lerp(
        sphere.current.position.y,
        Math.sin(state.clock.elapsedTime / 1.5) / 6 +
          (hover ? state.mouse.y / 2 : 0),
        0.2
      );
    }
  });

  // Springs for color and overall looks, this is state-driven animation
  // React-spring is physics based and turns static props into animated values
  const [{ wobble, coat, color, ambient, env }] = useSpring(
    {
      wobble: down ? 1.2 : hover ? 1.05 : 1,
      coat: !hover ? 0.04 : 0.5,
      ambient: !hover ? 1.5 : 1.0,
      env: !hover ? 0.4 : 1,
      color: hover ? highlightColor : backgroundColor,
      config: (n) =>
        n === "wobble" && hover && { mass: 2, tension: 1000, friction: 10 },
    },
    [hover, down]
  );

  const faceTexture = useLoader(
    TextureLoader,
    imageUrl ? imageUrl : "/assets/blank-texture.png"
  );
  faceTexture.wrapS = RepeatWrapping;
  faceTexture.wrapT = RepeatWrapping;
  faceTexture.repeat.set(repeatX, repeatY);

  return (
    <>
      <PerspectiveCamera makeDefault position={[0, 0, 5]} fov={75}>
        <a.ambientLight intensity={ambient} />
        <a.pointLight
          ref={light}
          position-z={-15}
          intensity={env}
          color={highlightColor}
        />
      </PerspectiveCamera>
      <a.mesh
        ref={sphere}
        scale={wobble}
        onPointerOver={() => onHover(true)}
        onPointerOut={() => onHover(false)}
        onPointerDown={() => setDown(true)}
        onPointerUp={() => {
          setDown(false);
          onClick && onClick();
        }}
      >
        <sphereBufferGeometry args={[1, 64, 64]} position={[0, 2, 0]} />
        <AnimatedMaterial
          map={faceTexture}
          color={color}
          envMapIntensity={env}
          clearcoat={coat}
          clearcoatRoughness={0.5}
          metalness={0.1}
        />
      </a.mesh>
      <ContactShadows
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, -1.6, 0]}
        opacity={0.8}
        width={15}
        height={15}
        blur={2.5}
        far={1.6}
      />
    </>
  );
}
