import { useEffect, useRef, useMemo } from "react";
import { Vector2 } from "three";
import { extend, useThree, useFrame } from "@react-three/fiber";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";
//import { GlitchPass } from "./effects/GlitchPass";
//import { WaterPass } from "./effects/WaterPass";

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  //WaterPass,
  UnrealBloomPass,
  FilmPass,
  //GlitchPass,
});

export default function Effect({ down, bloom }) {
  const composer = useRef();
  const { scene, gl, size, camera } = useThree();
  const aspect = useMemo(() => new Vector2(size.width, size.height), [size]);
  useEffect(() => void composer.current.setSize(size.width, size.height), [
    size,
  ]);
  useFrame(() => composer.current.render(), 1);
  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      {/*<waterPass attachArray="passes" factor={1.5} />*/}
      {bloom && (
        <unrealBloomPass attachArray="passes" args={[aspect, ...bloom]} />
      )}
      {/*<glitchPass attachArray="passes" factor={down ? 1 : 0} />*/}
    </effectComposer>
  );
}
