class SoundManager {
	constructor(props) {
		this.tracks = {};
		this.callback = null;
		this.soundIsOn = true;
	}

	setCallback(callback) {
		this.callback = callback;
	}

	addTrack(soundElementId) {
		const el = document.getElementById(soundElementId);
		if (el) {
			this.tracks[soundElementId] = {
				el,
				shouldPlay: false,
				playing: false,
				pause: el.paused,
			};
		} else {
			console.log("Sound not found", soundElementId);
		}
		//console.log("Sound Tracks", this.tracks);
	}

	play(soundElementId) {
		//console.log("playing", soundElementId);
		this.tracks[soundElementId].shouldPlay = true;
		if (this.soundIsOn) {
			this.tracks[soundElementId].el.play().catch((error) => {
				//console.log("Could not play", soundElementId, error);
				if (this.callback) this.callback(false);
			});
		}
	}

	pause(soundElementId) {
		//console.log("pausing", soundElementId);
		this.tracks[soundElementId].shouldPlay = false;
		this.tracks[soundElementId].el.pause();
	}

	resumePlaying() {
		this.soundIsOn = true;
		if (this.callback) this.callback(true);
		Object.values(this.tracks).forEach((track) => {
			if (track.shouldPlay) {
				//console.log("playing", track);
				track.el.play().catch((error) => {
					//console.log("Could not play", track, error);
					if (this.callback) this.callback(false);
				});
			}
		});
	}

	pauseAll() {
		this.soundIsOn = false;
		if (this.callback) this.callback(false);
		Object.values(this.tracks).forEach((track) => {
			//console.log("pausing", track);
			track.el.pause();
		});
	}
}

export default SoundManager;
