import { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
	getTeam,
	getTeamParticipants,
	saveParticipant,
} from "../../utils/store-functions";

export default function Team(props) {
	let { batchId, teamId } = useParams();
	const [team, setTeam] = useState({});
	const [participants, setParticipants] = useState([]);

	const onReleaseAll = () => {
		// Set `released` on all participants in the team
		const promises = participants.map((p) => {
			const update = {
				...p,
			};
			update.batches[batchId].released = true;
			return saveParticipant(p.id, update);
		});
		// Reload list of participants
		Promise.all(promises).then(() => {
			getTeamParticipants(teamId, batchId).then(setParticipants);
		});
	};

	useEffect(() => {
		if (teamId) getTeam(teamId).then(setTeam);
	}, [teamId]);

	useEffect(() => {
		if (batchId && teamId)
			getTeamParticipants(teamId, batchId).then(setParticipants);
	}, [batchId, teamId]);

	const participantList = participants.map((p, i) => {
		let icon = <img src="/assets/icon-declined.svg" width="20" alt="" />;
		if (batchId && p.batches && p.batches[batchId]) {
			const details = p.batches[batchId];
			if (details.approved && details.released) {
				icon = (
					<img
						src="/assets/icon-check-double.svg"
						width="20"
						alt=""
					/>
				);
			} else if (details.approved) {
				icon = (
					<img
						src="/assets/icon-check-single.svg"
						width="20"
						alt=""
					/>
				);
			}
		}
		return (
			<div key={i} className="row center">
				{icon}&ensp;
				<NavLink to={`/admin/batch/${batchId}/participants/${p.id}`}>
					{p.name}
				</NavLink>
			</div>
		);
	});

	return (
		<div className="Team col">
			<div></div>
			<div>Team {team.name}</div>
			<div className="smallprint">
				Batch {batchId} – {teamId}
			</div>
			&nbsp;
			<div className="col">
				<span>Members</span>
				{participantList}
			</div>
			&nbsp;
			<button onClick={onReleaseAll}>Release Certificates</button>
		</div>
	);
}
