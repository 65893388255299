import { Fragment } from "react";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { addBatch } from "../../utils/store-functions";

function findNextBatchNumber(batches) {
	let highest = 0;
	batches.forEach((batch) => {
		let id = parseInt(batch.id);
		if (id > highest) highest = id;
	});
	return highest + 1;
}

export default function Batches({ batches }) {
	const batchList = batches.map((b, i) => (
		<NavLink to={`${b.id}`} key={i}>
			{b.id}
		</NavLink>
	));

	const navigate = useNavigate();

	return (
		<Fragment>
			<div className="col list">
				<h3>Batches</h3>
				<button
					onClick={() => {
						const nextBatch = findNextBatchNumber(batches);
						addBatch(nextBatch).then(() => {
							navigate(`${nextBatch}`);
						});
					}}
				>
					Add next Batch
				</button>
				&nbsp;
				{batchList}
			</div>
			<Outlet />
		</Fragment>
	);
}
