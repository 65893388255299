export const tracks = ["PM", "IxD", "SE", "AI", "AC", "PMC", "IP"];
export const templates = {
	DPS: {
		PM: [
			"dps-pm-2023-steffen-brigitte",
			"dps-pm-2023-steffen",
			"dps-pm-2023-brigitte",
			"dps-pm-2023-appreciation-sb",
			"dps-vdps-2023-steffen-brigitte",
			"dps-pm",
		],
		IxD: [
			"dps-ixd-2023-marcus-melike",
			"dps-ixd-2023-marcus",
			"dps-ixd-2023-melike",
			"dps-ixd-2023-appreciation-mm",
			"dps-ixd",
		],
		SE: [
			"dps-se-2024-daniel-nebo-eya",
			"dps-se-2023-daniel-natalia-eya",
			"dps-se-2023-daniel",
			"dps-se-2023-natalia",
			"dps-se-2023-eya",
			"dps-se-2023-appreciation-dne",
			"dps-se",
		],
		AI: ["dps-ai-2023", "dps-ai-2023-appreciation", "dps-ai"],
		AC: ["dps-ac"],
	},
	DS: {
		PM: ["stmd-pm"],
		IxD: ["stmd-ixd"],
		SE: ["stmd-se"],
		AI: ["stmd-ai"],
		AC: ["stmd-ac"],
		IP: ["stmd-ip"],
	},
	DML: {
		PMC: [
			"dps-pmc-2023-bastian-kathrin",
			"dps-pmc-2024-andromeda",
			"dps-pmc-2024-catalysts",
			"dps-pmc",
			"dps-pmc-2023-bastian",
			"dps-pmc-2023-kathrin",
			"dps-pmc-2023-appreciation-bk",
		],
	},
};
