import { Suspense, useRef, useState, useEffect, useCallback } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

import WobbleSphereImage from "../components/WobbleSphereImage";
import Swarm from "../components/Swarm";
import Effects from "../components/Effects";

import "./Certificate.css";

export default function Certificate({
	user,
	participant,
	batch,
	imageUrl,
	soundManager,
}) {
	const [hoverSphere, setHoverSphere] = useState(false);
	const [imageDataUrl, setImageDataUrl] = useState(null);

	const mouse = useRef([0, 0]);
	const onMouseMove = useCallback(
		({ clientX: x, clientY: y }) =>
			(mouse.current = [
				x - window.innerWidth / 2,
				y - window.innerHeight / 2,
			]),
		[]
	);

	useEffect(() => {
		if (imageUrl) {
			fetch(imageUrl, {
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			})
				.then((response) => {
					if (response.ok) {
						return response.blob();
					} else {
						throw new Error(
							`Could not download picture: ${response.statusText}`
						);
					}
				})
				.then((blob) => {
					const dataUrl = URL.createObjectURL(blob);

					const img = document.createElement("img");
					img.onload = function (event) {
						// Dynamically create a canvas element
						const canvas = document.createElement("canvas");

						// const canvas = document.getElementById("canvas");
						const ctx = canvas.getContext("2d");
						ctx.canvas.width = 640;
						ctx.canvas.height = 640;

						// Fill canvas
						ctx.fillStyle = "#3A2708";
						ctx.fillRect(0, 0, 640, 640);

						// Draw picture into texture
						ctx.drawImage(img, 170, 170, 300, 300);

						// Draw our circle mask incl. outside rect
						ctx.fillStyle = "#3A2708";
						ctx.beginPath();
						ctx.arc(
							320, // x
							320, // y
							150, // radius
							0, // start angle
							2 * Math.PI // end angle
						);
						ctx.rect(640, 0, -640, 640);
						ctx.fill("evenodd");

						// Show resized image in preview element
						const canvasDataUrl = canvas.toDataURL("image/png");
						setImageDataUrl(canvasDataUrl);

						img.remove();
					};
					img.src = dataUrl;
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [imageUrl]);

	useEffect(() => {
		if (hoverSphere) {
			soundManager.play("energyLoop");
		} else {
			soundManager.pause("energyLoop");
		}
	}, [hoverSphere, soundManager]);

	return (
		<div className="Certificate">
			<Canvas dpr={[1, 2]} onMouseMove={onMouseMove}>
				<Suspense fallback={null}>
					{true && (
						<WobbleSphereImage
							imageUrl={imageDataUrl}
							hover={hoverSphere}
							onClick={() => {
								soundManager.play("applause");
							}}
							onHover={setHoverSphere}
							highlightColor="#F8C069"
							backgroundColor="#3A2708"
						/>
					)}
					{true && (
						<Swarm
							count={8000}
							mouse={mouse}
							highlightColor="#F8C069"
							backgroundColor="#202020"
						/>
					)}
					<Effects
						down={false}
						bloom={hoverSphere ? [2, 1, 0] : null}
					/>
					<OrbitControls
						enablePan={false}
						enableZoom={false}
						maxPolarAngle={Math.PI / 2}
						minPolarAngle={Math.PI / 2}
					/>
				</Suspense>
			</Canvas>
		</div>
	);
}
