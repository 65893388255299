import { getFirebase } from "./get-firebase";
import {
    getFirestore,
    collection,
    doc,
    query,
    where,
    /* orderBy, */
    /* limit, */
    addDoc,
    setDoc,
    getDoc,
    getDocs,
    getDocFromServer,
    onSnapshot,
    updateDoc,
    Timestamp,
} from "firebase/firestore";
import { getStorage, ref as fileRef, getDownloadURL } from "firebase/storage";

const firebaseApp = getFirebase();
const db = getFirestore();
const storage = getStorage(firebaseApp);

export { db, storage };

export function isAdmin(user, callback) {
    return getDocFromServer(doc(db, "settings/roles"))
        .then((doc) => doc.exists())
        .catch((error) => {
            return false;
        });
}

export function getRoles() {
    return getDoc(doc(db, `settings/roles`)).then((doc) => {
        if (doc.exists()) {
            return doc.data();
        } else {
            return null;
        }
    });
}

export function saveRoles(roles) {
    return setDoc(doc(db, `settings/roles`), roles);
}

export function getBatches(/*where = null*/) {
    const q = query(collection(db, "batches"));

    return getDocs(q).then((querySnapshot) => {
        const batches = [];
        querySnapshot.forEach((doc) => {
            batches.push({ ...doc.data(), id: doc.id });
        });
        batches.sort((a, b) => {
            return b.id.localeCompare(a.id, "en", {
                numeric: true,
                sensitivity: "base",
            });
        });
        return batches;
    });
}

export function subscribeBatches(/*where = null,*/ callback) {
    const q = query(collection(db, "batches"));

    return onSnapshot(q, (querySnapshot) => {
        const batches = [];
        querySnapshot.forEach((doc) => {
            batches.push({ ...doc.data(), id: doc.id });
        });
        batches.sort((a, b) => {
            return b.id.localeCompare(a.id, "en", {
                numeric: true,
                sensitivity: "base",
            });
        });
        callback && callback(batches);
    });
}

export function getBatchParticipants(batchNr) {
    const q = query(
        collection(db, `participants`),
        where(`batches.${batchNr}.batch`, "==", batchNr),
    );
    return getDocs(q).then((querySnapshot) => {
        const participants = [];
        querySnapshot.forEach((doc) => {
            participants.push({ ...doc.data(), id: doc.id });
        });
        return participants;
    });
}

export function subscribeBatchParticipants(batchNr, callback) {
    const q = query(
        collection(db, `participants`),
        where(`batches.${batchNr}.batch`, "==", batchNr),
    );
    return onSnapshot(q, (querySnapshot) => {
        const participants = [];
        querySnapshot.forEach((doc) => {
            participants.push({ ...doc.data(), id: doc.id });
        });
        participants.sort((a, b) => {
            return a.name.localeCompare(b.name, "en", {
                numeric: true,
                sensitivity: "base",
            });
        });
        callback && callback(participants);
    });
}

export function getParticipant(email) {
    return getDoc(doc(db, `/participants/${email}`)).then((doc) => {
        if (doc.exists()) {
            return { ...doc.data(), id: doc.id };
        } else {
            return null;
        }
    });
}

export function subscribeParticipant(email, callback) {
    return onSnapshot(doc(db, `/participants/${email}`), (doc) => {
        if (doc.exists()) {
            callback({ ...doc.data(), id: doc.id });
        } else {
            callback(null);
        }
    });
}

export function getBatchParticipantImageUrl(email) {
    return getDownloadURL(
        fileRef(storage, `participants/${email}/texture.png`),
    ).catch((error) => {
        console.warn("Picture not found for ", email);
        return null;
    });
}

export function getStorageFileUrl(path) {
    return getDownloadURL(fileRef(storage, path)).catch((error) => {
        console.warn("File not found in storage", path);
        return null;
    });
}

export function getTeams(batchNr = null) {
    const q = query(
        collection(db, `teams`),
        batchNr ? where("batch", "==", batchNr) : undefined,
    );
    return getDocs(q).then((querySnapshot) => {
        const teams = [];
        querySnapshot.forEach((doc) => {
            teams.push({ ...doc.data(), id: doc.id });
        });
        teams.sort((a, b) => {
            return a.name.localeCompare(b.name, "en", {
                numeric: true,
                sensitivity: "base",
            });
        });
        return teams;
    });
}

export function getTeam(teamId) {
    return getDoc(doc(db, `teams/${teamId}`)).then((doc) => {
        return { ...doc.data(), id: doc.id };
    });
}

export function addTeam(team) {
    return addDoc(collection(db, `teams`), team).then((docRef) => {
        return getDoc(docRef).then((doc) => {
            return { ...doc.data(), id: doc.id };
        });
    });
}

export function getTeamParticipants(teamId, batchNr) {
    const q = query(
        collection(db, `participants`),
        where(`batches.${batchNr}.team`, "==", teamId),
    );
    return getDocs(q).then((querySnapshot) => {
        const participants = [];
        querySnapshot.forEach((doc) => {
            participants.push({ ...doc.data(), id: doc.id });
        });
        participants.sort((a, b) => {
            return a.name.localeCompare(b.name, "en", {
                numeric: true,
                sensitivity: "base",
            });
        });
        return participants;
    });
}

export function saveParticipant(participantId, participant) {
    return setDoc(doc(db, `participants/${participantId}`), participant);
}

export function addBatch(batchId, batch) {
    return setDoc(doc(db, `batches/${batchId}`), {
        title: `Batch#${batchId}`,
        startDate: Timestamp.fromDate(new Date()),
        endDate: Timestamp.fromDate(new Date()),
    });
}

export function updateBatch(batchId, fields) {
    if (fields.startDate)
        fields.startDate = Timestamp.fromDate(new Date(fields.startDate));
    if (fields.endDate)
        fields.endDate = Timestamp.fromDate(new Date(fields.endDate));
    return updateDoc(doc(db, `batches/${batchId}`), fields);
}

/* FIXME – private Urls either need special auth permissions or signed URLs
export function getPrivateCertificate(sharedId) {
    // @todo sanitize shared ID
    return Promise.allSettled([
        getDownloadURL(
            fileRef(storage, `certificates/${sharedId}/certificate.pdf`),
        ),
        getDownloadURL(
            fileRef(
                storage,
                `certificates/${sharedId}/certificate-preview-1.png`,
            ),
        ),
        getDownloadURL(
            fileRef(storage, `certificates/${sharedId}/social-preview.png`),
        ),
    ]).then((results) => {
        if (results[0].status === "fulfilled" && results[0].value) {
            return {
                certificateDownloadUrl: results[0].value,
                certificatePreviewUrl: results[1].value,
                socialPreviewUrl: results[2].value,
            };
        } else {
            return null;
        }
    });
} */

export function getSharedCertificate(sharedId) {
    // @todo sanitize shared ID
    return Promise.allSettled([
        getDoc(doc(db, `shared/${sharedId}`)).then((doc) =>
            doc.exists() ? doc.data() : null,
        ),
        getDownloadURL(fileRef(storage, `shared/${sharedId}/certificate.pdf`)),
        getDownloadURL(
            fileRef(storage, `shared/${sharedId}/certificate-preview.png`),
        ),
        //getDownloadURL(fileRef(storage, `shared/${sharedId}/picture.png`)),
    ]).then((results) => {
        if (results[0].status === "fulfilled" && results[0].value) {
            return {
                ...results[0].value,
                certificateDownloadUrl: results[1].value,
                certificatePreviewUrl: results[2].value,
                //pictureUrl: results[3].value,
            };
        } else {
            return null;
        }
    });
}

export function importParticipant(participant, batchNr, details) {
    const key = participant.email.toLowerCase();

    if (key) {
        return getDoc(doc(db, `participants/${key}`)).then((snapshot) => {
            if (snapshot.exists()) {
                // Update existing entry
                const data = snapshot.data();
                const update = {
                    ...data,
                    ...participant,
                };

                if (data.batches[batchNr]) {
                    update.batches[batchNr] = {
                        ...data.batches[batchNr],
                        ...details,
                    };
                } else {
                    update.batches[batchNr] = {
                        ...details,
                        approved: true,
                        released: false,
                    };
                }
                return updateDoc(doc(db, `participants/${key}`), update);
            } else {
                // Create new database entry
                const data = {
                    ...participant,
                    batches: {
                        [batchNr]: {
                            ...details,
                            approved: true,
                            released: false,
                        },
                    },
                };
                return setDoc(doc(db, `participants/${key}`), data);
            }
        });
    } else {
        throw new Error("Importing a participant requires an email");
    }
}

/* export function getParticipant(email, callback) {
    const q = query(
        collection(db, "participants"),
        where("email", "==", email)
    );

    return getDocs(q).then((querySnapshot) => {
        if (querySnapshot.size === 1) {
            const participantsUpdate = [];
            querySnapshot.forEach((doc) => {
                participantsUpdate.push(doc.data());
            });
            callback && callback(participantsUpdate[0]);
            return participantsUpdate[0];
        } else {
            throw new Error(`Could not find a participant record for ${email}`);
        }
    });
} */

export function searchParticipant(namePart) {
    const part = namePart.toLowerCase();
    const q = query(
        collection(db, `participants`),
        where("nameLower", ">=", part),
        where("nameLower", "<=", part + "\uf8ff"),
    );

    return getDocs(q).then((querySnapshot) => {
        const searchResults = [];
        querySnapshot.forEach((doc) => {
            searchResults.push({ ...doc.data(), id: doc.id });
        });
        return searchResults;
    });
}
