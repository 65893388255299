import { useEffect } from "react";
import { Routes, Route, Outlet, useNavigate, useMatch } from "react-router-dom";
import Navbar from "../components/Navbar";

import Batches from "./admin/Batches";
import Batch from "./admin/Batch";
import BatchLayout from "./admin/BatchLayout";
import BatchImport from "./admin/BatchImport";
//import BatchProcessShares from "./admin/BatchProcessShares";
import BatchProcessRefresh from "./admin/BatchProcessRefresh";
import BatchParticipants from "./admin/BatchParticipants";
import BatchTeams from "./admin/BatchTeams";
import Participant from "./admin/Participant";
import Team from "./admin/Team";
import UserList from "./admin/UserList";
import Settings from "./admin/Settings";

import "./Admin.css";

function Layout({ user, batches, signOut }) {
  const navigate = useNavigate();
  const match = useMatch("/admin");

  useEffect(() => {
    if (batches.length > 0 && match) {
      navigate(`batch/${batches[0].id}/participants`);
    }
  }, [navigate, match, batches]);

  return (
    <div className="col">
      <Navbar
        user={user}
        batches={batches}
        signOut={signOut}
        headline="Admin area"
      />
      <div className="Admin row">
        <Outlet />
      </div>
    </div>
  );
}

export default function Admin({ user, batches, signOut }) {
  return (
    <Routes>
      <Route
        element={<Layout user={user} batches={batches} signOut={signOut} />}
      >
        <Route index element={<div>No Batch selected yet</div>} />
        <Route path="settings" element={<Settings />}>
          <Route path="batches" element={<Batches batches={batches} />}>
            <Route path=":batchId" element={<Batch batches={batches} />} />
          </Route>
          <Route path="user" element={<UserList user={user} />} />
        </Route>
        <Route path="batch/:batchId/*" element={<BatchLayout />}>
          <Route path="import" element={<BatchImport />} />
          <Route path="refresh" element={<BatchProcessRefresh user={user} />} />
          {/*<Route
            path="share-prep"
            element={<BatchProcessShares user={user} />}
          />*/}
          <Route path="participants" element={<BatchParticipants />}>
            <Route
              path=":participantEmail"
              element={<Participant user={user} />}
            />
          </Route>
          <Route path="teams" element={<BatchTeams />}>
            <Route path=":teamId" element={<Team />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}
