import { Fragment, useState, useEffect } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom";
import { getTeams } from "../../utils/store-functions";

export default function BatchTeams() {
	let { batchId } = useParams();
	const [teams, setTeams] = useState([]);

	useEffect(() => {
		getTeams(batchId).then(setTeams);
	}, [batchId]);

	const teamList = teams.map((t, i) => {
		return (
			<NavLink to={`${t.id}`} key={i}>
				{t.name}
			</NavLink>
		);
	});

	return (
		<Fragment>
			<div className="col list">{teamList}</div>
			<Outlet />
		</Fragment>
	);
}
