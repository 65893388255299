import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getFirebase } from "../utils/get-firebase";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import "./Login.css";

// eslint-disable-next-line no-unused-vars
const firebase = getFirebase();
const auth = getAuth();
const provider = new GoogleAuthProvider();

export default function Login({ user }) {
  const location = useLocation();

  const accountType = location.pathname.startsWith("/admin")
    ? "unternehmertum.de"
    : "dpschool.io";

  // Give Google login a hint for the desired domain
  provider.setCustomParameters({
    hd: accountType,
  });

  if (user !== null && location.pathname === "/login") {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="Login col center justify-center">
      <img
        src="/assets/logos/dps-gold.svg"
        alt="DPS Certificates"
        with={240}
        height={240}
      />
      <div className="col center">
        <button
          className="large"
          onClick={() => {
            signInWithPopup(auth, provider)
              .then((result) => {
                /* 
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(
                  result
                );
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...
                */
              })
              .catch((error) => {
                /* // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(
                  error
                );
                // ... */
              });
          }}
        >
          <span>Sign in with Google</span>
        </button>

        <p className="smallprint">Please use your {accountType} account.</p>
      </div>
    </div>
  );
}
