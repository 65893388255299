import { Fragment } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom";

export default function BatchLayout(props) {
	let { batchId } = useParams();

	return (
		<Fragment>
			<div className="col list">
				<h3>Batch&nbsp;{batchId}</h3>
				<NavLink to="participants">Participants</NavLink>
				<NavLink to="teams">Teams</NavLink>
				&nbsp;
				<NavLink to="import">Import</NavLink>
				<NavLink to="refresh">Refresh</NavLink>
			</div>

			<Outlet />
		</Fragment>
	);
}
