import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TaskRunner from "../../components/TaskRunner";
import Tooltip from "../../components/Tooltip";
import backendUrl from "../../utils/backend-url";

import { getBatchParticipants } from "../../utils/store-functions";

export default function BatchProcessRefresh({ user }) {
	let { batchId } = useParams();
	const [participants, setParticipants] = useState([]);
	const [token, setToken] = useState(null);

	useEffect(() => {
		getBatchParticipants(batchId).then(setParticipants);
	}, [batchId]);

	useEffect(() => {
		if (user) {
			user.getIdToken().then(setToken);
		}
	}, [user]);

	const onProcessRow = (participant, i) => {
		if (token) {
			return fetch(`${backendUrl}refreshCertificate`, {
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {
					Authorization: "Bearer " + token,
					"Participant-Batch": batchId,
					"Participant-Id": participant.email.toLowerCase(),
				},
			}).then((response) => {
				if (response.ok) {
					console.log(
						`Refreshed ${i+1}/${participants.length}: ${participant.name}`,
					);
					return response.json();
				} else {
					throw new Error(
						`Could not refresh exports for ${participant.email}: ${response.statusText}`,
					);
				}
			});
		}
	};

	if (!token) {
		return <div className="col">Waiting for auth ...</div>;
	}

	return (
		<div className="col">
			<TaskRunner
				items={participants}
				itemLabel="participants to refresh"
				task={onProcessRow}
				onFinish={() => console.log("All Done")}
				startLabel="Start refreshing certificates and shares"
				tooltip={
					<Tooltip
						info={
							"Clicking will update the sharing settings for all participants."
						}
					/>
				}
			/>
		</div>
	);
}
