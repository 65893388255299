import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { searchParticipant } from "../utils/store-functions";
import AutoComplete from "../components/AutoComplete";
import Tooltip from "../components/Tooltip";

import "./Navbar.css";

function AutoCompleteItem({ data, highlight, extendedProps }) {
  return (
    <li
      className="SearchItem col"
      style={highlight ? { backgroundColor: "#ff8c1a80" } : {}}
      {...extendedProps}
    >
      {data.name}
      <span className="small">{data.email}</span>
      {data.batches && Object.keys(data.batches).length > 1 && (
        <div className="row">
          {Object.values(data.batches).map((batch, index) => (
            <div className="Tag" key={index}>
              {batch.batch}
            </div>
          ))}
        </div>
      )}
    </li>
  );
}

export default function Navbar({ user, batches, signOut }) {
  let { batchId } = useParams();
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  const onChangeBatch = (event) => {
    if (event.target.value) {
      navigate(`/admin/batch/${event.target.value}/participants`);
    }
  };

  return (
    <div className="Navbar row center">
      <header className="row center grow">
        <Link to="/admin">DPS Certificates</Link>
        <div className="row">
          <select name="batch" value={batchId} onChange={onChangeBatch}>
            {batches.map((batch, i) => {
              return (
                <option key={i} value={batch.id}>
                  {batch.title}
                </option>
              );
            })}
          </select>
          &nbsp;
          <Link
            to="settings/batches"
            style={{ fontSize: "1.5em", textDecoration: "none" }}
          >
            ⚙︎
          </Link>
        </div>
        <div className="row center justify-center grow">
          <AutoComplete
            label="Search"
            placeholder="by name"
            items={searchResults}
            Item={AutoCompleteItem}
            onChange={(event) => {
              if (event.selectedItem) {
                const goToBatch = event.selectedItem.batches.hasOwnProperty(
                  batchId
                )
                  ? batchId
                  : Object.values(event.selectedItem.batches)[
                      Object.values(event.selectedItem.batches).length - 1
                    ].batch;
                navigate(
                  `/admin/batch/${goToBatch}/participants/${event.selectedItem.email}`
                );
              }
            }}
            onInputValueChange={({ inputValue }) => {
              /* setInputItems(
              items.filter((item) =>
                item.name.toLowerCase().startsWith(inputValue.toLowerCase())
              )
            );*/
              searchParticipant(inputValue).then(setSearchResults);
            }}
          />
          &nbsp;
          <Tooltip
            info={
              "Search only works when you type the name from the beginning."
            }
          />
        </div>
      </header>
      {user && (
        <>
          <div className="User row center">
            <span className="Name">{user.displayName}</span>
            {user.photoURL && (
              <div className="Avatar row center">
                <img
                  src={user.photoURL}
                  alt="User Avatar"
                  width={50}
                  height={50}
                />
              </div>
            )}
          </div>
          <button className="large" onClick={signOut}>
            Logout
          </button>
        </>
      )}
    </div>
  );
}
