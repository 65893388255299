import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { updateBatch } from "../../utils/store-functions";

function timestampToDateString(timestamp) {
	if (!timestamp) return "";
	const date = timestamp.toDate();
	return date.toISOString().substr(0, 10);
}

export default function Batch({ batches }) {
	let { batchId } = useParams();
	let batch = batches.find((b) => b.id === batchId);

	return (
		<Fragment>
			{batch && (
				<div className="col list">
					<h3>{batch.title}</h3>
					<label>
						<span style={{ width: "30px" }}>Von</span>
						<input
							type="date"
							name="startDate"
							value={timestampToDateString(batch.startDate)}
							onChange={(event) => {
								updateBatch(batchId, {
									startDate: event.target.value,
								});
							}}
						/>
					</label>
					<label>
						<span style={{ width: "30px" }}>Bis</span>
						<input
							type="date"
							name="endDate"
							value={timestampToDateString(batch.endDate)}
							onChange={(event) => {
								updateBatch(batchId, {
									endDate: event.target.value,
								});
							}}
						/>
					</label>
					&nbsp;
					<Link to={`/admin/batch/${batchId}/participants`}>
						Show Participants &gt;
					</Link>
				</div>
			)}
		</Fragment>
	);
}
