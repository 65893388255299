import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthContext from "./AuthContext";

import Blank from "./pages/Blank";
import Login from "./pages/Login";
import Frontpage from "./pages/Frontpage";
// import Shared from "./pages/Shared";
import Admin from "./pages/Admin";

import { getFirebase } from "./utils/get-firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getParticipant,
  subscribeBatches,
  isAdmin as userIsAdmin,
} from "./utils/store-functions";

import "./App.css";

// eslint-disable-next-line no-unused-vars
const firebase = getFirebase();
const auth = getAuth();

function App() {
  const [user, setUser] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [batches, setBatches] = useState([]);

  const signOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      setParticipant(null);
      setIsAdmin(false);
      setError(null);
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // https://firebase.google.com/docs/reference/js/firebase.User
      } else {
        setUser(null);
        setParticipant(null);
        setIsAdmin(false);
      }
    });
    return function cleanup() {
      unsubscribe();
    };
  }, []);

  // @todo – this is running even if in /admin --> maybe refactor into Certificate or change route structure?
  useEffect(() => {
    if (user && user.emailVerified && participant === null) {
      setLoading(true);
      getParticipant(user.email).then((participant) => {
        setLoading(false);
        if (participant) {
          setParticipant(participant);
        }
      });
    }
  }, [user, participant]);

  useEffect(() => {
    if (user && !user.isAnonymous) {
      userIsAdmin(user).then(setIsAdmin);
    } else {
      setIsAdmin(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unsubscribe = subscribeBatches(setBatches);
      return function cleanup() {
        unsubscribe();
      };
    }
  }, [user, setBatches]);

  // @todo fix position of download link on mobile (is covered by UI)
  // @todo refactor certificate reveal to support multiple batches

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <AuthContext.Consumer>
        {({ user }) => (
          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  user ? (
                    participant ? (
                      <Frontpage
                        user={user}
                        participant={participant}
                        signOut={signOut}
                      />
                    ) : loading === false ? (
                      <Blank
                        user={user}
                        signOut={signOut}
                        error={{
                          message:
                            "Sorry, could not find your participant record.",
                        }}
                      />
                    ) : (
                      <Blank
                        user={user}
                        signOut={signOut}
                        error={{
                          message: "Loading ...",
                        }}
                      />
                    )
                  ) : (
                    <Login user={user} />
                  )
                }
              />
              <Route path="login" element={<Login user={user} />} />
              {isAdmin && (
                <Route
                  path="admin/*"
                  element={
                    isAdmin ? (
                      <Admin
                        user={user}
                        batches={batches}
                        isAdmin={isAdmin}
                        signOut={signOut}
                        error={error}
                      />
                    ) : (
                      <Login user={user} />
                    )
                  }
                />
              )}
              {/*<Route path="shared/:shareId" element={<Shared user={user} />} />*/}
              <Route
                path="*"
                element={
                  user ? (
                    <Blank user={user} signOut={signOut} error={error} />
                  ) : (
                    <Login user={user} />
                  )
                }
              />
            </Routes>
          </Router>
        )}
      </AuthContext.Consumer>
    </AuthContext.Provider>
  );
}

export default App;
