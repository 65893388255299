import { Fragment, useRef, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import Tooltip from "../../components/Tooltip";
import {
	getTeam,
	getParticipant,
	getStorageFileUrl,
	saveParticipant,
} from "../../utils/store-functions";
import backendUrl from "../../utils/backend-url";

import { templates } from "../../config/dps";

// @todo refactor into config
const imageMinSize = 400;
const imageMaxSize = 1500;

export default function Participant({ user }) {
	let { batchId, participantEmail } = useParams();
	const fileInput = useRef(null);
	const [participant, setParticipant] = useState({});
	const [loading, setLoading] = useState(true);
	const [imageUrl, setImageUrl] = useState(null);
	const [certificateDownloadUrl, setCertificateDownloadUrl] = useState(null);
	const [certificatePreviewUrl, setCertificatePreviewUrl] = useState(null);
	const [socialPreviewUrl, setSocialPreviewUrl] = useState(null);
	const [imageIsProcessing, setImageIsProcessing] = useState(false);
	const [team, setTeam] = useState({});
	const [refreshing, setRefreshing] = useState(false);

	let details = {};
	if (participant.batches && participant.batches[batchId]) {
		details = participant.batches[batchId];
	} else {
		details.missing = true;
	}

	const onChangeCertificate = (event) => {
		const update = {
			...participant,
		};
		update.batches[batchId].template = event.target.value;
		saveParticipant(participantEmail, update).then(() => {
			setParticipant(update);
		});
	};

	const onClickRefreshExport = (user) => {
		setRefreshing(true);
		user.getIdToken().then((token) => {
			return fetch(`${backendUrl}refreshCertificate`, {
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {
					Authorization: "Bearer " + token,
					"Participant-Batch": batchId,
					"Participant-Id": participantEmail,
				},
			})
				.then((response) => {
					setRefreshing(false);
					if (response.ok) {
						return response.json();
					} else {
						throw new Error(
							`Could not refresh exports: ${response.statusText}`,
						);
					}
				})
				.then((response) => {
					console.log(response);
					if (response.shareId) {
						// @todo – do a proper state update instead of this hack
						window.location.reload();
					}
				})
				.catch((error) => {
					// @todo show error in UI
					console.error(error);
					return false;
				});
		});
	};

	const onClickUpload = () => {
		fileInput.current.click();
	};

	const toggleApproved = () => {
		const update = {
			...participant,
		};
		update.batches[batchId].approved =
			!participant.batches[batchId].approved;
		saveParticipant(participantEmail, update).then(() => {
			setParticipant(update);
		});
	};

	const toggleReleased = () => {
		const update = {
			...participant,
		};
		update.batches[batchId].released =
			!participant.batches[batchId].released;
		saveParticipant(participantEmail, update).then(() => {
			setParticipant(update);
		});
	};

	// @todo refactor into helper function (look up image resize code in dps-upload project)
	const onImageSelected = (event) => {
		const selectedFile = event.target.files[0];
		const readerDataUrl = new FileReader();

		// Read image into memory as base64 encoded data url
		readerDataUrl.addEventListener("load", () => {
			// Sanity checks on image dimensions
			const img = new Image();
			img.onload = function () {
				let valid = true;
				if (this.width < imageMinSize || this.height < imageMinSize) {
					valid = false;
					window.alert(
						`Please use an image larger than ${imageMinSize} pixels`,
					);
				} else if (
					this.width > imageMaxSize ||
					this.height > imageMaxSize
				) {
					valid = false;
					window.alert(
						`Please use an image smaller than ${imageMaxSize} pixels`,
					);
				} else {
					setImageIsProcessing(true);
					// setWarning(null);
				}

				if (valid) {
					user.getIdToken().then((token) => {
						return fetch(`${backendUrl}addPicture`, {
							method: "POST",
							cache: "no-cache",
							headers: {
								Authorization: "Bearer " + token,
								"Content-Type": selectedFile.type,
								"X-User": participantEmail,
							},
							body: selectedFile, // file or blob
						})
							.then((response) => {
								if (response.ok) return response;
								throw response;
							})
							.then(() => {
								setImageIsProcessing(false);
								getStorageFileUrl(
									`participants/${participantEmail}/texture.png`,
								).then(setImageUrl);
							})
							.catch((error) => {
								console.error(error);
							});
					});
				}
			};
			img.src = readerDataUrl.result;
		});
		readerDataUrl.readAsDataURL(selectedFile);

		// Reset input to allow selecting the same image again
		event.target.value = "";
	};

	useEffect(() => {
		if (participantEmail) {
			setParticipant({});
			setImageUrl(null);
			setCertificateDownloadUrl(null);
			setCertificatePreviewUrl(null);
			setSocialPreviewUrl(null);
			setLoading(true);
			getParticipant(participantEmail).then((participant) => {
				setParticipant(participant);
				setLoading(false);
			});

			getStorageFileUrl(
				`participants/${participantEmail}/texture.png`,
			).then(setImageUrl);
		}
	}, [batchId, participantEmail]);

	useEffect(() => {
		if (details.shareId) {
			getStorageFileUrl(
				`participants/${participantEmail}/${batchId}/certificate.pdf`,
			).then(setCertificateDownloadUrl);
			getStorageFileUrl(
				`participants/${participantEmail}/${batchId}/certificate-preview-1.png`,
			).then(setCertificatePreviewUrl);
			getStorageFileUrl(
				`participants/${participantEmail}/${batchId}/social-preview.png`,
			).then(setSocialPreviewUrl);
		}
	}, [details.shareId, batchId, participantEmail]);

	useEffect(() => {
		if (details.team !== team.id) {
			getTeam(details.team).then(setTeam);
		}
	}, [details.team, team.id]);

	if (loading) {
		return <div className="Participant col"></div>;
	}

	let batchDetailsAndDownload = null;
	if (!details.missing) {
		batchDetailsAndDownload = (
			<Fragment>
				<div className="small">
					<b>Batch {details.batch}</b>
				</div>
				<div>Program: {details.program}</div>
				<div>Track: {details.track}</div>
				<div>
					Team:{" "}
					<NavLink to={`/admin/batch/${batchId}/teams/${team.id}`}>
						{team.name}
					</NavLink>
				</div>
				&nbsp;
				<div>
					Certificate{" "}
					<select
						size="1"
						value={details.template}
						onChange={onChangeCertificate}
					>
						{Object.keys(templates).map((program) => {
							return (
								<Fragment key={program}>
									<option disabled>{program}</option>
									{Object.entries(templates[program]).map(
										([track, tplList]) => {
											return (
												<Fragment
													key={`${program}-${track}-list`}
												>
													{tplList.map((tpl) => (
														<option
															key={tpl}
															value={tpl}
														>
															{tpl}
														</option>
													))}
												</Fragment>
											);
										},
									)}
								</Fragment>
							);
						})}
					</select>
				</div>
				<div>
					<label>
						Certificate approved?
						<input
							type="checkbox"
							checked={details.approved}
							onChange={toggleApproved}
						/>
					</label>
					<Tooltip info="Certificates are assumed to be approved by default, but track heads can decide not to approve a certificate in some cases." />
				</div>
				<div>
					<label>
						Certificate released?
						<input
							type="checkbox"
							checked={details.released}
							onChange={toggleReleased}
						/>
					</label>
					<Tooltip info="Certificates are supposed to be released during the certificate ceremony. It's recommended to do so from the team page." />
				</div>
				<p>
					Certificate ID:
					<br />
					{details.shareId ? details.shareId : "Not yet created."}
				</p>
				&nbsp;
				<div>
					<button onClick={() => onClickRefreshExport(user)}>
						{refreshing
							? "Refreshing Exports ..."
							: "Refresh Certificate"}
					</button>
					&emsp;
					{certificateDownloadUrl && (
						<a
							className="button"
							href={certificateDownloadUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							Download Certificate
						</a>
					)}
				</div>
				{details.shared ? (
					<p>
						This certificate is shared publicly →{" "}
						<a
							href={`/shared/${details.shareId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							Open Link
						</a>{" "}
						🔖
					</p>
				) : (
					<p>This certificate is not shared publicly.</p>
				)}
				<br />
				<br />
				{certificatePreviewUrl && (
					<img
						src={certificatePreviewUrl}
						alt="Certificate Preview"
						width="400"
					/>
				)}
				<br />
				<br />
				{socialPreviewUrl && (
					<img
						src={socialPreviewUrl}
						alt="Social Media Preview"
						width="400"
					/>
				)}
			</Fragment>
		);
	} else if (participant.batches) {
		batchDetailsAndDownload = (
			<div>
				<p>
					{participant.name} did not participate in Batch#{batchId}.
				</p>
				<p>
					You may want to look here:{" "}
					{Object.values(participant.batches).map((info, i) => (
						<span key={i}>
							Batch#{info.batch} in {info.track} track.&nbsp;
						</span>
					))}
				</p>
			</div>
		);
	}

	const multipleBatches =
		Object.values(participant.batches).length > 1 ? true : false;

	return (
		<div className="Participant col">
			<div className="row">
				<div>
					<b>{participant.name}</b>
					<br />
					<a href={`mailto:${participant.email}`}>
						{participant.email}
					</a>
				</div>
				<div
					className={`portrait ${imageIsProcessing ? "loading" : ""}`}
					style={{
						backgroundImage: `url(${
							imageUrl ? imageUrl : "/assets/icon-add-avatar.svg"
						})`,
					}}
					onClick={onClickUpload}
				/>
				<input
					ref={fileInput}
					onChange={onImageSelected}
					type="file"
					accept="image/jpeg,image/png"
					className="hidden"
				/>
			</div>
			{multipleBatches && (
				<div className="Filter">
					{multipleBatches ? "Batches" : "Batch"}
					&nbsp;
					{Object.values(participant.batches).map((b, i) => (
						<NavLink
							key={i}
							to={`/admin/batch/${b.batch}/participants/${participant.email}`}
							className={`button small`}
						>
							{b.batch}
						</NavLink>
					))}
				</div>
			)}
			&nbsp;
			{batchDetailsAndDownload}
		</div>
	);
}
